import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import inview from '../lib/inview'

export default component((node, ctx) => {
  // if (inview(node, ctx.getState().wh)) return

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      off()
      add(node, 'is-visible')
    }
  })

  return () => {
      off()
  }
})
