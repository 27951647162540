import { component } from 'picoapp'
import { qsa, add, qs, on, has, toggle } from 'martha'
import { gsap, ScrollTrigger } from 'gsap/all'
import { SplitText } from '../lib/SplitText'

export default component((node, ctx) => {
  var sections = gsap.utils.toArray('.section')
  gsap.registerPlugin(ScrollTrigger)

  window.scrollTo(0,0)

  const animateTitle = () => {
    let titleSplit = gsap.timeline(),
      splitWords = new SplitText(qsa('.js-splitTitle', node), {
        type: 'words',
        position: 'relative',
      })

    let words = splitWords.words

    titleSplit.fromTo(
      words,
      { opacity: 0, y: 30 },
      {
        duration: 0.5,
        opacity: 1,
        y: 15,
        stagger: 0.1,
        ease: 'cubic.out',
        onComplete: () => {
          add(node, 'has-animated')
        },
      },
    )

    on(window, 'resize', () => {
      splitWords.revert()
    })
  }

  ScrollTrigger.create({
    trigger: node,
    start: 'top center',
    end: 'bottom center',
    onToggle: (self) => {
      toggle(qs('.js-slideInner', node), 'active')
      if (!has(node, 'has-animated')) {
        animateTitle()
      }
    },
  })

  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 1000)
})
