import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import { gsap, ScrollTrigger, Power2 } from 'gsap/all'
import choozy from 'choozy'
import { SplitText } from '../lib/SplitText'
import inview from '../lib/inview'

export default component((node, ctx) => {
  const animateLines = () => {
    let textToRight = gsap.timeline(),
      splitLines = new SplitText(qsa('.splitToRight p', node), {
        type: 'lines',
      })

    let lines = splitLines.lines

    textToRight.fromTo(
      lines,
      { opacity: 0, x: -15 },
      {
        duration: 0.5,
        opacity: 1,
        x: 0,
        stagger: 0.1,
        ease: 'power2.out',
      },
    )

    on(window, 'resize', () => {
      splitLines.revert()
    })
  }

  const off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      off()
      animateLines()
    }
  })

  return () => {
    off()
  }
})
