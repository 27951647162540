import { component } from 'picoapp'
import choozy from 'choozy'
import { on, qsa, toggle, has, qs, add, remove } from 'martha'

export default component((node, ctx) => {
  let { form, success, optinradio, optin, checkbox, submit, textarea } =
    choozy(node)
  let inputs = qsa('input', node)
  let selects = qsa('.js-selectOption', node)
  let emptyValues = true
  const formId = node.dataset.id

  const checkForm = () => {
    console.log('checked')
    inputs?.some((input) => {
      if (input.value.length > 0) {
        if (textarea?.value.length > 0) {
          selects?.some((select) => {
            if (select.value != 'placeholder') {
              emptyValues = false
              ctx.emit('form:checked')
            } else {
              emptyValues = true
              ctx.emit('form:checked')
            }
          })
        } 
      } else {
        emptyValues = true
        ctx.emit('form:checked')
      }
    })
  } 

  inputs.forEach((input) => {
    on(input, 'input', () => {
      checkForm()
      if (emptyValues) {
        submit.disabled = true
      } else {
        submit.removeAttribute('disabled')
      }
    })
  })

  if (textarea) {
    on(textarea, 'input', () => {
      checkForm()
      if (emptyValues) {
        submit.disabled = true
      } else {
        submit.removeAttribute('disabled')
      }
    })
  }

  if (optin) {
    on(optin, 'click', () => {
      toggle(checkbox, 'active')
      if (has(checkbox, 'active')) {
        qs('#optinradio', node).setAttribute('checked', 'checked')
      } else {
        qs('#optinradio', node).removeAttribute('checked')
      }
      checkForm()
    })
  }

  if (selects) {
    selects.forEach((select) => {
      on(select, 'click', () => {
        console.log('select changed')
        checkForm()
        if (emptyValues) {
          submit.disabled = true
        } else {
          submit.removeAttribute('disabled')
        }
      })
    })
  }

  ctx.on('form:checked', () => {
    if (emptyValues) {
      submit.disabled = true
    } else {
      submit.removeAttribute('disabled')
    }
  })

  on(form, 'submit', (e) => {
    e.preventDefault()

    const data = new FormData(e.target)

    const value = Object.fromEntries(data.entries())

    console.log(value)

    fetch(`https://submit-form.com/${formId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then(function (response) {
        add(form, 'hidden')
        remove(success, 'hidden')
      })
      .catch(function (error) {
        console.error(error)
      })
  })
})
