import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import { gsap, ScrollTrigger } from 'gsap/all'
import choozy from 'choozy'

export default component((node, ctx) => {
  var sections = gsap.utils.toArray('.section')
  gsap.registerPlugin(ScrollTrigger)

  let { img } = choozy(node)
  let isMobile = document.documentElement.clientWidth < 850

  ScrollTrigger.matchMedia({
    '(min-width: 850px)': function () {
      ScrollTrigger.create({
        trigger: node,
        start: 'top center',
        end: 'bottom center',
        onUpdate: (self) => {
          gsap.set(img, { y: -1 * self.progress * 200 })
        },
      })
    },
  })

  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 1000)

  return () => {
    ScrollTrigger.getAll().forEach((t) => t.kill())
  }
})
