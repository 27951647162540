import { component } from 'picoapp'
import { qsa, add, remove } from 'martha'
import { gsap, ScrollTrigger } from 'gsap/all'
import choozy from 'choozy'

export default component((node, ctx) => {
  var sections = gsap.utils.toArray('.section')
  gsap.registerPlugin(ScrollTrigger)
  const tl = gsap.timeline()

  let { prevCount, activeCount, nextCount } = choozy(node)

  qsa('.js-step', node).forEach((step) => {
    gsap.to(step, {
      scrollTrigger: {
        trigger: step,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        // end: '+=500',
        onUpdate: (self) => {
          if (self.progress > 0.0 && self.progress < 1) {
            remove(step, 'm:opacity-10')
          } else {
            // if (!) {
            add(step, 'm:opacity-10')
          }
        },
        onEnter: () => {
          if (step.dataset.index == 1) return
          nextCount.innerHTML = parseInt(step.dataset.index)

          tl.fromTo(
            activeCount,
            { y: 0 },
            { y: '-100%', duration: 0.2, ease: 'cubic.easeOut' },
          ).fromTo(
            nextCount,
            { y: '100%' },
            {
              y: 0,
              duration: 0.2,
              ease: 'cubic.easeOut',
              onComplete: () => {
                activeCount.innerHTML = parseInt(step.dataset.index)
                nextCount.innerHTML = parseInt(step.dataset.index) + 1

                gsap.set(activeCount, { y: 0 })
                gsap.set(nextCount, { y: '100%' })
              },
            },
            '-=.2',
          )
        },
        onEnterBack: () => {
          if (step.dataset.index >= qsa('.js-step', node).length) return
          prevCount.innerHTML = parseInt(step.dataset.index)

          tl.fromTo(
            activeCount,
            { y: 0 },
            { y: '100%', duration: 0.2, ease: 'cubic.easeOut' },
          ).fromTo(
            prevCount,
            { y: '-100%' },
            {
              y: 0,
              duration: 0.3,
              ease: 'cubic.easeOut',
              onComplete: () => {
                activeCount.innerHTML = parseInt(step.dataset.index)
                prevCount.innerHTML = parseInt(step.dataset.index) - 1

                gsap.set(activeCount, { y: 0 })
                gsap.set(prevCount, { y: '-100%' })
              },
            },
            '-=.2',
          )
        },
      },
    })
  })

    ScrollTrigger.refresh()

  return () => {
    ScrollTrigger.getAll().forEach((t) => t.kill())
  }
})
