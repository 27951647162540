import { component } from 'picoapp'
import { add, qs, qsa, remove } from 'martha'
import choozy from 'choozy'
import lottie from 'lottie-web'
import inview from '../lib/inview'

export default component((node, ctx) => {
  let { animation, animationIntro, globe, globeText } = choozy(node)

//   const articleIntro = lottie.loadAnimation({
//     container: animationIntro, // the dom element that will contain the animation
//     renderer: 'svg',
//     loop: false,
//     autoplay: false,
//     name: 'intro',
//     path: '/animations/article_intro.json', // the path to the animation json
//   })

  const articleLoop = lottie.loadAnimation({
    container: animation, // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path: '/animations/article_loop.json', // the path to the animation json
  })

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
    //   off()
      articleLoop.play()
    } else{
        articleLoop.pause()
    }
  })

  return () => {
    off()
  }
})
