import { component } from 'picoapp'
import choozy from 'choozy'
import EmblaCarousel from 'embla-carousel'
import { on } from 'martha'

export default component((node, ctx) => {
  let { primary, marqueeLogo } = choozy(node)

  let isMobile = document.documentElement.clientWidth < 850

  if (!isMobile) {
    marqueeLogo.style.width = `${primary.offsetWidth - 75}px`

    setTimeout(() => {
      marqueeLogo.style.width = `${primary.offsetWidth - 75}px`
    }, 2000)
  }

  const offSize = on(window, 'resize', () => {
    if (!isMobile) {
      marqueeLogo.style.width = `${primary.offsetWidth - 75}px`
    } else {
      marqueeLogo.style.width = ''
    }
  })

  return () => {
    offSize()
  }

})
