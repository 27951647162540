import { component } from 'picoapp'
import { qsa, qs, remove, add, on } from 'martha'
import gsap from 'gsap'

export default component((node, ctx) => {
  const tl = gsap.timeline()
  let container = qs('.js-imgContainer')
  let curScroll

  tl.to('.hero-bar', {scaleX: 1, stagger: .1, ease: 'expo.out', duration: .7, onComplete: () => {
    qsa('.hero-bar', node).forEach((bar) => {
        bar.style.transform = ''
        remove(bar, 'animate')
        remove(qs('.js-heroImg'), 'hide')
        add(container, 'float')
    })
  }})

  on(window, 'scroll', () => {
    curScroll = window.pageYOffset || document.documentElement.scrollTop

      if (curScroll > 700){
        remove(container, 'float')
      } else{
        add(container, 'float')
      }
  })
})
