import { component } from 'picoapp'
import { on, qsa, add, toggle, has } from 'martha'
import choozy from 'choozy'
import lottie from 'lottie-web'
import inview from '../lib/inview'

export default component((node, ctx) => {
  let { animation, globe, globeText } = choozy(node)

  const globeBg = lottie.loadAnimation({
    container: animation, // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/animations/globe-background.json', // the path to the animation json
  })

  const globeWords = lottie.loadAnimation({
    container: globeText, // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/animations/globe-text.json', // the path to the animation json
  })

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      // off()
      globeBg.play()
      setTimeout(() => {
        add(globe, 'animate')
      }, 1000)
        globeWords.play()
    } else{
      globeWords.pause()
    }
  })

  return () => {
    off()
  }
})
