import { component } from 'picoapp'
import choozy from 'choozy'
import { remove, add, qs, on, qsa } from 'martha'
import EmblaCarousel from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'

export default component((node, ctx) => {
  let { carousel, next, prev } = choozy(node)

  // const embla = EmblaCarousel(carousel, { loop: true, skipSnaps: false })

  const options = {
    loop: true,
    skipSnaps: false,
    align: 'center',
    delay: 4000,
    draggable: false,
    speed: 100,
  }

  const autoplayRoot = (carousel) => carousel.parentElement // Root node
  //   const autoplay = Autoplay(options, autoplayRoot)

  const embla = EmblaCarousel(carousel, {
    loop: true,
    skipSnaps: false,
    align: 'center',
    draggable: true,
  })

    prev.addEventListener('click', embla.scrollPrev, false)
    next.addEventListener('click', embla.scrollNext, false)

    // const onSelect = () => {
    //   autoplay.reset()
    //   qsa('.carousel-slide', node).forEach((slide) => {
    //     remove(slide, 'active')
    //   })
    //   add(qs(`.carousel-slide[data-index="${embla.selectedScrollSnap() + 1}"]`), 'active')
    // }

    // embla.on('select', onSelect) // Add event listener
})
