import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { line } = choozy(node)
  let lines = qsa('.js-line')
  let curLine = 1
  let phrases = qsa('.js-text')
  let cancelType = false

  var i = 0
  let targetText = qs(`.js-text[data-index='1']`)
  let txt = targetText.dataset.text /* The text */

  function typeWriter() {
    if (cancelType) return
    var speed = 50 /* The speed/duration of the effect in milliseconds */

    if (i < txt.length) {
      targetText.innerHTML += txt.charAt(i)
      i++
      setTimeout(typeWriter, speed)
    } else {
      setTimeout(() => {
        qsa('.js-text', node).forEach((textLine) => {
          textLine.innerHTML = ''
        })

        i = 0
        curLine = curLine + 1
        if (curLine > phrases.length) curLine = 1 
        targetText = qs(`.js-text[data-index='${curLine}']`)
        add(qs('.js-text:not(.hidden)', node), 'hidden')
        remove(targetText, 'hidden')
        txt = targetText.dataset.text /* The text */

        typeWriter(txt)

      }, 3000)
    }
  }

  typeWriter(txt)

  return () => {
    cancelType = true
  }
})
