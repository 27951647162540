import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import { gsap, ScrollTrigger } from 'gsap/all'
import choozy from 'choozy'

export default component((node, ctx) => {
  var sections = gsap.utils.toArray('.section')
  gsap.registerPlugin(ScrollTrigger)

  let { activeCount } = choozy(node)
  let isMobile = document.documentElement.clientWidth < 850


  qsa('.js-date').forEach((date) => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: date,
        start: 'top center-=100',
        end: 'bottom center-=100',
        scrub: true,
        onUpdate: (self) => {
          if (self.progress > 0.00 && self.progress < 1) {
            if (!isMobile) {
              remove(qs('.js-info', date), 'm:opacity-10')
            }
            add(date, 'text-blue')
          } else {
            if (!isMobile) {
              add(qs('.js-info', date), 'm:opacity-10')
            }
            remove(date, 'text-blue')
          }
        },
      },
    })

    gsap.set(date, { opacity: 0.35 })

    tl.fromTo(date, { opacity: 0.35 }, { opacity: 1 })
      .fromTo(date, { opacity: 1 }, { opacity: 1 })
      .fromTo(date, { opacity: 1 }, { opacity: 0.35 })
  })

  setTimeout(() => {
    qsa('.js-date').forEach((date) => {
      gsap.set(date, { opacity: 0.35 })
    })
    ScrollTrigger.refresh()
  }, 1000)

  return () => {
    ScrollTrigger.getAll().forEach((t) => t.kill())
  }
})
