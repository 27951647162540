import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import { gsap, Expo } from 'gsap'

export default component((node, ctx) => {
  let menu = qs('.js-menu')
  const tl = gsap.timeline()
  let isAnimating = false
  let lastScroll = 0

  on(window, 'scroll', () => {
    curScroll = window.pageYOffset || document.documentElement.scrollTop

    if (curScroll > 30) {
      add(qs('.js-headerInner'), 'bg-white')
      if (lastScroll < curScroll) {
        add(qs('header'), '-translate-y-full')
      } else {
        remove(qs('header'), '-translate-y-full')
      }
    } else {
      remove(qs('.js-headerInner'), 'bg-white')
    }
    lastScroll = curScroll
  })

  qsa('.js-menuToggle').forEach((tog) => {
    on(tog, 'click', () => {
      if (isAnimating) return
      isAnimating = true

      if (!has(document.body, 'menu-open')) {
        add(document.body, 'menu-open')

        tl.to(menu, { y: 0, duration: 0.5, ease: 'expo.out' }).fromTo(
          '.js-menuItem',
          { opacity: 0, y: 20 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.05,
            duration: 1,
            ease: 'expo.out',
            onComplete: () => {
              isAnimating = false
            },
          },
          '-=.15',
        )
      } else {
        remove(document.body, 'menu-open')

        tl.to('.js-menuItem', {
          opacity: 0,
          y: 20,
          stagger: 0.05,
          duration: 1,
          ease: 'expo.out',
        }).to(
          menu,
          {
            y: '-100%',
            duration: 0.5,
            ease: 'expo.out',
            onComplete: () => {
              isAnimating = false
            },
          },
          '-=.6',
        )
      }
    })
  })
})
