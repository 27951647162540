import { component } from 'picoapp'
import { qsa, add, qs, on } from 'martha'
import choozy from 'choozy'
import inview from '../lib/inview'
import { gsap, Expo } from 'gsap'

export default component((node, ctx) => {
  let numHeight = qsa('.js-countNum')[0].offsetHeight
  var count
  // var cntStart = (numHeight * 10 - numHeight) * -1
  let delay = 0
  let hasAnimated = false
  let ints = qsa('.js-integer', node)

  const counter = () => {
    ints.forEach((int) => {
      gsap.set(int, { y: numHeight })
    })

    count = function () {
      ints.forEach((int) => {
        gsap.to(int, {
          y:
            int.dataset.value == 0
              ? numHeight * 9 * -1
              : (numHeight * 10 - numHeight * (11 - int.dataset.value)) * -1,
          duration: 3,
          delay: delay + 0.2,
          ease: Expo.easeInOut,
          onComplete: () => {
            hasAnimated = true
          },
        })
      })
      delay = delay + 0.05
    }

    count()
  }

  const offSize = on(window, 'resize', () => {
    if (hasAnimated) {
      ints.forEach((int) => {
        int.style = null
        int.innerHTML = int.dataset.value
      })
    } else {
      numHeight = qsa('.js-countNum')[0].offsetHeight
    }
  })

  setTimeout(() => {
    numHeight = qsa('.js-countNum')[0].offsetHeight
  }, 1500)

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
      off()
      counter()
    }
  })

  return () => {
    counter()
    offSize()
  }
})
