import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import scroll from './components/scroll'
import sticky from './components/sticky'
import highlight from './components/highlight'
import drawer from './components/drawer'
import stack from './components/stack'
import counter from './components/counter'
import stickyCount from './components/stickyCount'
import typewriter from './components/typewriter'
import carousel from './components/carousel'
import form from './components/form'
import timeline from './components/timeline'
import header from './components/header'
import footer from './components/footer'
import formSelect from './components/formSelect'
import parallax from './components/parallax'
import splitText from './components/splitText'
import storytelling from './components/storytelling'
import hero from './components/hero'
import featuredStories from './components/featuredStories'
import marquee from './components/marquee'
import animationGlobe from './components/animationGlobe'
import animationArticle from './components/animationArticle'
import animationPuzzle from './components/animationPuzzle'
import signup from './components/signup'

const components = {
  lazy,
  scroll,
  sticky,
  highlight,
  drawer,
  stack,
  counter,
  stickyCount,
  typewriter,
  carousel,
  form,
  timeline,
  header,
  footer,
  formSelect,
  parallax,
  splitText,
  storytelling,
  hero,
  featuredStories,
  marquee,
  animationGlobe,
  animationArticle,
  animationPuzzle,
  signup
}

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    // { family: 'GT Walsheim' },
    // { family: 'GT Walsheim', options: { weight: 300 } },
    // { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
  ],
}

export default picoapp(components, state)
