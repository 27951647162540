import { component } from 'picoapp'
import { qsa, add } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let highlights = qsa('.text-highlight', node)

  highlights.forEach((lite) => {
      add(lite, 'transition')
  });
})