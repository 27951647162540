import { component } from 'picoapp'
import { add, qs, qsa, remove } from 'martha'
import choozy from 'choozy'
import lottie from 'lottie-web'
import inview from '../lib/inview'

export default component((node, ctx) => {
  let { animation } = choozy(node)


  const animatePuzzle = lottie.loadAnimation({
    container: animation, // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path: '/animations/puzzle_bg.json', // the path to the animation json
  })

  let off = ctx.on('tick', ({ wh }) => {
    if (inview(node, wh)) {
    //   off()
      animatePuzzle.play()
    } else{
       animatePuzzle.pause()
    }
  })

  return () => {
    off()
  }
})
