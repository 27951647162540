import { component } from 'picoapp'
import choozy from 'choozy'
import { add, on, qs, qsa, remove, toggle } from 'martha'

export default component((node, ctx) => {
  let { selectToggle, selectList, selectOption, chevron, option, optionPlaceholder } = choozy(node)

  on(selectToggle, 'click', () => {
    toggle(selectList, 'hidden')
    toggle(chevron, 'rotate-180')
  })

  on(selectOption, 'click', (e) => {
    add(selectList, 'hidden')
    add(chevron, 'rotate-180')

    qsa('.js-option', node).forEach((opt) => {
      opt.removeAttribute('selected')
    })

    let targetOption = qs(`.js-option[value="${e.target.dataset.value}"]`, node)

    targetOption.setAttribute('selected', 'selected')
    optionPlaceholder.innerHTML = targetOption.value
    remove(optionPlaceholder, 'opacity-20')
  })
})
