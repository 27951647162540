import { component } from 'picoapp'
import { qsa, add, qs, on, remove, has } from 'martha'
import choozy from 'choozy'
import astrochimp from 'astrochimp'

export default component((node, ctx) => {
  let { form, success, error, email, formContent } = choozy(node)

  on(form, 'submit', (e) => {
    e.preventDefault()

    const data = { EMAIL: email.value }

    astrochimp(form.action, data, (err, data) => {
      if (err) {
        remove(error, 'hidden')
      } else{
        add(formContent, 'hidden')
        remove(success, 'hidden')
        add(error, 'hidden')
      }
    })
  })
})
