import { remove, qsa, qs, add } from 'martha'

export default function checkPage() {
  if (window.location.pathname == '/') {
    add(qs('body'), 'home')
  } else {
    remove(qs('body'), 'home')
    qsa('.js-navLink').forEach((link) => {
      if (link.getAttribute('href') == window.location.pathname) {
        add(link, 'active')
      } else {
        remove(link, 'active')
      }
    })
  }
}
