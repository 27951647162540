import { component } from 'picoapp'
import { qsa, add, qs } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { story } = choozy(node)

  const cycleStories = () => {
    node.insertBefore(qsa('.js-story', node)[2], qsa('.js-story', node)[0])
  }

  setInterval(cycleStories, 5000)
})
