import { component } from 'picoapp'
import { on, qsa, add, toggle, has } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { content, btn, arrow, contentInner } = choozy(node)
  let highlights = qsa('.text-highlight', node)

  console.log(contentInner.offsetHeight);

  qsa('.js-content')[0].style.maxHeight = `${qsa('.js-contentInner')[0].offsetHeight}px`

  on(btn, 'click', () => {
    if (has(content, 'active')) {
      console.log('close', 0);
      content.style.maxHeight = '0px'
      toggle(arrow, 'rotate-180')
      toggle(content, 'active')
    } else{
      let contentHeight = contentInner.offsetHeight

      console.log('open', contentHeight);


      content.style.maxHeight = `${contentHeight}px`
      toggle(arrow, 'rotate-180')
      toggle(content, 'active')
    }
  })
})
